import agenda from "./council/agenda.js";
import councilperiod from "./council/councilperiod.js";
import decision from "./council/decision.js";

export default [
  {
    path: "/council",
    meta: { needsAuth: true, },
    component: function () {
      return import("../views/council");
    },
    children: [
      {
        path: "",
        name: "Council.Homepage",
        component: () =>
          import("../views/council/homepage"),
      },

      ...agenda,
      ...decision,
      ...councilperiod,
      {
        path: "calendar/:hexkey?",
        meta: {
          helpTag: "council:calendar",
        },
        component: () =>
          import("../views/general/calendar"),
        children: [
          {
            path: "",
            name: "Council.Calendar",
            component: () =>
              import("../views/general/calendar/calendar"),
          },
        ],
      },
      {
        name: "Council.Search",
        path: "search",
        meta: {
          helpTag: "council:search",
        },
        component: () =>
          import("../views/general/search"),
      },
      {
        path: "question",
        meta: {
          helpTag: "council:question",
        },
        component: () =>
          import("../views/council/question"),
        children: [
          {
            path: "extended/:hexkey?",
            name: "Council.Question.ExtendedList",
            component: () =>
            import(
              "../components/general/lists/extendedList"
              ),
            },
            {
              path: ":hexkey?",
              name: "Council.Question",
              component: () =>
                import(
                  "../components/general/lists/simpleList"
                ),
            },
        ],
      },
      {
        path: "beleidsdocument",
        meta: {
          helpTag: "council:beleidsdocument",
        },
        component: () =>
          import(
            "../views/council/beleidsdocument"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.BeleidsDocument.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.BeleidsDocument",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "bestuursdocument",
        meta: {
          helpTag: "council:bestuursdocument",
        },
        component: () =>
          import(
            "../views/council/bestuursdocument"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.BestuursDocument.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.BestuursDocument",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      // {
      //   path: "boardportal",
      //   name: "Council.BoardPortal",
      //   component: () =>
      //     import(
      //       "../views/council/boardportal"
      //     ),
      // },
      {
        path: "boardportal",
        meta: {
          helpTag: "council:boardportal",
        },
        component: () =>
          import(
            "../views/council/boardportalOld"
          ),
        children: [
          {
            path: ":hexkey?",
            name: "Council.BoardPortal",
            component: () =>
              import(
                "../views/council/boardportalOld/list"
              ),
          },
        ],
      },
      {
        path: "collegeberichten",
        meta: {
          helpTag: "council:collegeberichten",
        },
        component: () =>
          import(
            "../views/council/collegeberichten"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.CollegeBerichten.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.CollegeBerichten",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "motie",
        meta: {
          helpTag: "council:motie",
        },
        component: () =>
          import(
            "../views/council/motie"
          ),
        children: [
          {
            path: "extended/:hexkey?",
            name: "Council.Motie.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },
          {
            path: ":hexkey?",
            name: "Council.Motie",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "portfolio",
        meta: {
          helpTag: "council:portfolio",
        },
        component: () =>
          import(
            "../views/council/portfolio"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.Portfolio.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.Portfolio",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "postin",
        meta: {
          helpTag: "council:postin",
        },
        component: () =>
          import(
            "../views/council/postin"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.Postin.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.Postin",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "raadsadres",
        meta: {
          helpTag: "council:raadsadres",
        },
        component: () =>
          import(
            "../views/council/raadsadres"
          ),
        children: [
         
          {
            path: "extended/:hexkey?",
            name: "Council.Raadsadres.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          }, {
            path: ":hexkey?",
            name: "Council.Raadsadres",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "raadsbesluit",
        meta: {
          helpTag: "council:raadsbesluit",
        },
        component: () =>
          import(
            "../views/council/raadsbesluit"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.Raadsbesluit.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.Raadsbesluit",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "toezegging",
        meta: {
          helpTag: "council:toezegging",
        },
        component: () =>
          import(
            "../views/council/toezegging"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.Toezegging.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.Toezegging",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "voorstel",
        meta: {
          helpTag: "council:voorstel",
        },
        component: () =>
          import(
            "../views/council/voorstel"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.Voorstel.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.Voorstel",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "actiepunten",
        meta: {
          helpTag: "council:actiepunten",
        },
        component: () =>
          import(
            "../views/council/actionpoints"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.ActionPoints.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.ActionPoints",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "statusoverview",
        meta: {
          helpTag: "council:statusoverview",
        },
        component: () =>
          import(
            "../views/council/statusoverview"
          ),
        children: [
          {
            path: ":hexkey?",
            name: "Council.StatusOverview",
            component: () =>
              import(
                "../views/council/statusoverview/list"
              ),
          },
        ],
      },
      {
        path: "preferences",
        meta: {
          helpTag: "council:preferences",
        },
        component: () => import("../views/general/preferences"),
        children: [
          {
            path: "",
            name: "Council.Preferences",
            component: () => import("../views/general/preferences/detail"),
          },
        ],
      },
      {
        path: "newsletter",
        meta: {
          helpTag: "council:newsletter",
        },
        component: () =>
          import(
            "../views/council/newsletter"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.Newsletter.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.Newsletter",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "decisionbook",
        meta: {
          helpTag: "council:decisionbook",
        },
        component: () =>
          import(
            "../views/council/decisionbook"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.DecisionBook.ExtendedList",
            meta: {
              breadcrumb: "Lijst",
            },
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.DecisionBook",
            meta: {
              breadcrumb: "Lijst",
            },
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "profile",
        meta: {
          helpTag: "council:profile",
          needsAuth: true,
        },
        component: () =>
          import("../views/council/profile"),
        children: [
          {
            path: "",
            name: "Council.Profile",
            component: () =>
              import(
                "../views/council/profile/list"
              ),
          },
          {
            path: "edit/:hexkey",
            name: "Council.Profile.Edit",
            component: () =>
              import(
                "../views/council/profile/edit"
              ),
          },
        ],
      },
      {
        path: "profilearchive",
        meta: {
          helpTag: "council:profilearchive",
          needsAuth: true,
        },
        component: () =>
          import("../views/council/profilearchive"),
        children: [
          {
            path: ":hexkey",
            name: "Council.ProfileArchive",
            component: () =>
              import(
                "../views/council/profilearchive/list"
              ),
          },
        ],
      },
      {
        path: "profilearchivefolder",
        meta: {
          helpTag: "council:profilearchivefolder",
          needsAuth: true,
        },
        component: () =>
          import("../views/council/profilearchivefolder"),
        children: [
          {
            path: ":hexkey",
            name: "Council.ProfileArchiveFolder",
            component: () =>
              import(
                "../views/council/profilearchivefolder/list"
              ),
          },
        ],
      },
      {
        path: "profilearchivedoc",
        meta: {
          helpTag: "council:profilearchivedoc",
          needsAuth: true,
        },
        component: () =>
          import("../views/council/profilearchivedoc"),
        children: [
          {
            path: ":prd/:fldr([a-z0-9]{32})?",
            name: "Council.ProfileArchiveDoc",
            component: () =>
              import(
                "../views/council/profilearchivedoc/list"
              ),
          },
        ],
      },
      {
        path: "skillstown",
        meta: {
          helpTag: "council:skillstown",
        },
        name: "Council.Skillstown",
        component: () =>
          import("../views/council/skillstown/index.vue"),
      },
      {
        path: "cmarchive",
        meta: {
          helpTag: "council:cmarchive",
        },
        component: () =>
          import(
            "../views/council/cmarchive"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.CmArchive.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.CmArchive",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "pubdocs",
        meta: {
          helpTag: "council:pubdocs",
        },
        component: () =>
          import("../views/council/pubdocs"),
        children: [
          {
            path: ":hexkey?",
            name: "Council.PublicDocument",
            meta: {
              extendedList: false,
            },
            component: () =>
              import(
                "../views/council/pubdocs/list"
              ),
          },
        ],
      },
      {
        path: "verordening",
        meta: {
          helpTag: "council:verordening",
        },
        component: () =>
          import(
            "../views/council/verordening"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Council.Verordening.ExtendedList",
            component: () =>
              import(
                "../views/council/verordening/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Council.Verordening",
            component: () =>
              import(
                "../views/council/verordening/list"
              ),
          },
        ],
      },
      {
        path: "weekbericht",
        meta: {
          helpTag: "council:weekbericht",
        },
        component: () =>
          import(
            "../views/council/weekbericht"
          ),
        children: [
          {
            path: ":hexkey?",
            name: "Council.WeekBericht",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          }
        ],
      },
      {
        path: "datepicker",
        meta: {
          helpTag: "council:datepicker",
        },
        component: () =>
          import(
            "../views/council/datepicker"
          ),
        children: [
          {
            path: ":hexkey",
            name: "Council.Datepicker",
            component: () =>
              import(
                "../views/council/datepicker/list"
              ),
          }
        ],
      },
      {
        path: "dagmap",
        meta: {
          helpTag: "council:dagmap",
        },
        component: () =>
          import(
            "../views/council/dagmap"
          ),
        children: [
          {
            path: "",
            name: "Council.Dagmap",
            component: () =>
              import(
                "../components/council/dagmap/list"
              ),
          },
          {
            path: ":hexkey/:ap_hexkey([a-z0-9]{32})?",
            name: "Council.Dagmap.Details",
            component: () =>
              import(
                "../components/council/dagmap/agenda"
              ),
          },
        ],
      },
      {
        path: "meeting",
        meta: {
          helpTag: "council:meeting",
        },
        component: () =>
          import("../views/council-public/meeting"),
        children: [
          {
            path: ":hexkey",
            name: "Council.Meeting.Details",
            component: () =>
              import(
                "../views/council-public/meeting/details"
              ),
          },
        ],
      },
      {
        path: "dossier",
        meta: {
          helpTag: "council:dossier",
        },
        component: () =>
          import("../views/general/dossier"),
        children: [
          {
            path: "",
            name: "Council.Dossier",
            component: () =>
              import(
                "../views/general/dossier/list"
              ),
          },
          {
            path: ":hexkey",
            name: "Council.Dossier.Details",
            component: () =>
              import(
                "../views/general/dossier/details"
              ),
          },
        ],
      },
      {
        path: "specialist",
        meta: {
          helpTag: "council:specialist",
        },
        component: () =>
          import("../views/council/specialist"),
        children: [
          {
            path: "",
            name: "Council.Beschikbaarheid",
            component: () =>
              import(
                "../views/council/specialist/list"
              ),
          },
        ],
      },
      {
        path: "event",
        meta: {
          helpTag: "council:event",
        },
        component: () =>
          import(
            "../views/council/event"
          ),
        children: [
          {
            path: ":hexkey?",
            name: "Council.Event",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "formats",
        meta: {
          helpTag: "council:formats",
        },
        component: () =>
          import("../views/council/formats"),
        children: [
          {
            path: ":hexkey?",
            name: "Council.Formats",
            meta: {
              extendedList: false,
            },
            component: () =>
              import(
                "../views/council/formats/list"
              ),
          },
        ],
      },
      {
        path: "processeddossiers",
        meta: {
          helpTag: "council:processeddossiers",
        },
        component: () =>
          import("../views/council/processeddossiers"),
        children: [
          {
            path: ":hexkey?",
            name: "Council.ProcessedDossiers",
            meta: {
              extendedList: false,
            },
            component: () =>
              import(
                "../views/council/processeddossiers/list"
              ),
          },
        ],
      },
      {
        path: "documentportal",
        meta: {
          helpTag: "council:documentportal",
        },
        component: () =>
          import(
            "../views/council/documentportal"
          ),
        children: [
          {
            path: ":hexkey?",
            name: "Council.DocumentPortal",
            component: () =>
              import(
                "../views/council/documentportal/list"
              ),
          },
        ],
      },
      {
        path: "woorequest",
        meta: {
          helpTag: "council:woorequest",
        },
        component: () =>
          import(
            "../views/council/woorequest"
          ),
        children: [
          {
            path: ":hexkey?",
            name: "Council.WooRequest",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "gemeenteboard",
        meta: {
          helpTag: "council:gemeenteboard",
        },
        name: "Council.GemeenteBoard",
        component: () =>
          import("../views/council/gemeenteboard"),
        children: [
          {
            path: "",
            name: "Council.GemeenteBoard",
            component: () =>
              import(
                "../views/council/gemeenteboard/list.vue"
              ),
          },
          {
            path: "envi",
            name: "Council.GemeenteBoard.Envi",
            component: () =>
              import(
                "../components/general/pages/extEnvironment.vue"
              ),
          }
        ],
      },
      {
        path: "dashboard",
        meta: {
          helpTag: "council:dashboard",
        },
        component: () =>
          import("../views/council/dashboard"),
        children: [
            {
              path: "",
              name: "Council.Dashboard",
              component: () =>
                import(
                  "../components/general/pages/dashboard"
                ),
            },
        ],
      },
    ],
  },
];
