import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    ...require("@/router/auth.js").default,
    ...require("@/router/college.js").default,
    ...require("@/router/party.js").default,
    ...require("@/router/council.js").default,
    ...require("@/router/public.js").default,

    {
      path: "/",
      name: "Public.Homepage",
      component: () =>
        import("../views/public/homepage"),
    },
    {
      path: "/menu",
      name: "Menu",
      component: () =>
        import("../views/menu"),
    },
    { name: 'General.NotFound', path: "/404", component: () => import("../views/general/404.vue") },
    { name: 'General.Sitemap', path: "/sitemap", component: () => import("../views/general/sitemap.vue") },
    { name: 'General.Changelog', path: "/changelog", component: () => import("../views/general/changelog.vue") },
    {
      path: "/preferences",
      meta: {
        needsAuth: true,
        helpTag: "general:preferences",
      },
      component: () => import("../views/general/preferences"),
      children: [
        {
          path: "",
          name: "General.Preferences",
          meta: {
            needsAuth: true,
          },
          component: () => import("../views/general/preferences/detail"),
        },
      ],
    },
    { path: "*", redirect: "/404" },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.needsAuth && !store.getters.getSID) {
    next({
      name: "Auth.Login",
      params: { needsLogin: "1" },
    });
  }
  next();
});

export default router;
