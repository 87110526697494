import agenda from "./public/agenda";
import councilperiod from "./public/councilperiod";

export default [
  {
    path: "/public",
    component: function () {
      return import("../views/public");
    },
    children: [
      {
        path: "",
        name: "General.Homepage",
        redirect: { name: 'Public.Homepage' }
      },

      ...agenda,

      {
        path: "beleidsdocument",
        meta: {
          helpTag: "public:beleidsdocument",
        },
        component: () =>
          import(
            "../views/public/beleidsdocument"
          ),
          
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Public.BeleidsDocument.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Public.BeleidsDocument",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "bestuursdocument",
        meta: {
          helpTag: "public:bestuursdocument",
        },
        component: () =>
          import(
            "../views/public/bestuursdocument"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Public.BestuursDocument.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Public.BestuursDocument",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "calendar/:hexkey?",
        meta: {
          helpTag: "public:calendar",
        },
        component: () =>
          import("../views/general/calendar"),
        children: [
          {
            path: "",
            name: "Public.Calendar",
            component: () =>
              import("../views/general/calendar/calendar"),
          },
        ],
      },
      {
        path: "cmarchive",
        meta: {
          helpTag: "public:cmarchive",
        },
        component: () =>
          import("../views/public/cmarchive"),
        children: [
          {
            path: ":hexkey?",
            name: "Public.CmArchive",
            meta: {
              extendedList: false,
            },
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "councildocument",
        meta: {
          helpTag: "public:councildocument",
        },
        component: () =>
          import(
            "../views/public/councildocument"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Public.CouncilDocument.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Public.CouncilDocument",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      ...councilperiod,
      {
        path: "dossier",
        meta: {
          helpTag: "public:dossier",
        },
        component: () =>
          import("../views/general/dossier"),
        children: [
          {
            path: "",
            name: "Public.Dossier",
            component: () =>
              import(
                "../views/general/dossier/list"
              ),
          },
          {
            path: ":hexkey",
            name: "Public.Dossier.Details",
            component: () =>
              import(
                "../views/general/dossier/details"
              ),
          },
        ],
      },
      {
        path: "meeting",
        meta: {
          helpTag: "public:meeting",
        },
        component: () =>
          import("../views/council-public/meeting"),
        children: [
          {
            path: ":hexkey",
            name: "Public.Meeting.Details",
            component: () =>
              import(
                "../views/council-public/meeting/details"
              ),
          },
        ],
      },
      {
        path: "motie",
        meta: {
          helpTag: "public:motie",
        },
        component: () =>
          import("../views/public/motie"),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Public.Motie.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Public.Motie",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "postin",
        meta: {
          helpTag: "public:postin",
        },
        component: () =>
          import("../views/public/postin"),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Public.Postin.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Public.Postin",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "profile",
        meta: {
          helpTag: "public:profile",
          needsAuth: true,
        },
        component: () =>
          import("../views/public/profile"),
        children: [
          {
            path: "",
            name: "Public.Profile",
            component: () =>
              import(
                "../views/public/profile/list"
              ),
          },
          {
            path: "edit/:hexkey",
            name: "Public.Profile.Edit",
            component: () =>
              import(
                "../views/public/profile/edit"
              ),
          },
        ],
      },
      {
        path: "profilearchive",
        meta: {
          helpTag: "public:profilearchive",
          needsAuth: true,
        },
        component: () =>
          import("../views/public/profilearchive"),
        children: [
          {
            path: ":hexkey",
            name: "Public.ProfileArchive",
            component: () =>
              import(
                "../views/public/profilearchive/list"
              ),
          },
        ],
      },
      {
        path: "profilearchivefolder",
        meta: {
          helpTag: "Public:profilearchivefolder",
          needsAuth: true,
        },
        component: () =>
          import("../views/public/profilearchivefolder"),
        children: [
          {
            path: ":hexkey",
            name: "Public.ProfileArchiveFolder",
            component: () =>
              import(
                "../views/public/profilearchivefolder/list"
              ),
          },
        ],
      },
      {
        path: "profilearchivedoc",
        meta: {
          helpTag: "public:profilearchivedoc",
          needsAuth: true,
        },
        component: () =>
          import("../views/public/profilearchivedoc"),
        children: [
          {
            path: ":prd/:fldr([a-z0-9]{32})?",
            name: "Public.ProfileArchiveDoc",
            component: () =>
              import(
                "../views/public/profilearchivedoc/list"
              ),
          },
        ],
      },
      {
        path: "pubdocs",
        meta: {
          helpTag: "public:pubdocs",
        },
        component: () =>
          import("../views/public/pubdocs"),
        children: [
          {
            path: ":hexkey?",
            name: "Public.PublicDocument",
            meta: {
              extendedList: false,
            },
            component: () =>
              import(
                "../views/public/pubdocs/list"
              ),
          },
        ],
      },
      {
        path: "question",
        meta: {
          helpTag: "public:question",
        },
        component: () =>
          import("../views/public/question"),
        children: [
         
          {
            path: "extended/:hexkey?",
            name: "Public.Question.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          }, {
            path: ":hexkey?",
            name: "Public.Question",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "raadsadres",
        meta: {
          helpTag: "public:raadsadres",
        },
        component: () =>
          import("../views/public/raadsadres"),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Public.Raadsadres.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Public.Raadsadres",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        name: "Public.Search",
        path: "search",
        meta: {
          helpTag: "public:search",
        },
        component: () =>
          import("../views/general/search"),
      },
      {
        name: "Public.MaatSearch",
        path: "maatsearch",
        meta: {
          helpTag: "public:maatsearch",
        },
        component: () =>
          import("../views/general/maatSearch"),
      },
      {
        path: "statusoverview",
        meta: {
          helpTag: "public:statusoverview",
        },
        component: () =>
          import(
            "../views/public/statusoverview"
          ),
        children: [
          {
            path: ":hexkey?",
            name: "Public.StatusOverview",
            component: () =>
              import(
                "../views/public/statusoverview/list"
              ),
          },
        ],
      },
      {
        path: "toezegging",
        meta: {
          helpTag: "public:toezegging",
        },
        component: () =>
          import("../views/public/toezegging"),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Public.Toezegging.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Public.Toezegging",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "verordening",
        meta: {
          helpTag: "public:verordening",
        },
        component: () =>
          import(
            "../views/public/verordening"
          ),
        children: [
          {
            path: ":hexkey?",
            name: "Public.Verordening",
            component: () =>
              import(
                "../views/public/verordening/list"
              ),
          },
          {
            path: "extended/:hexkey?",
            name: "Public.Verordening.ExtendedList",
            component: () =>
              import(
                "../views/public/verordening/extendedList"
              ),
          },
        ],
      },
      {
        path: "voorstel",
        meta: {
          helpTag: "public:voorstel",
        },
        component: () =>
          import("../views/public/voorstel"),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Public.Voorstel.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Public.Voorstel",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "weekbericht",
        meta: {
          helpTag: "public:weekbericht",
        },
        component: () =>
          import(
            "../views/public/weekbericht"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Public.WeekBericht.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Public.WeekBericht",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "preferences",
        meta: {
          needsAuth: true,
          helpTag: "public:preferences",
        },
        component: () => import("../views/general/preferences"),
        children: [
          {
            path: "",
            name: "Public.Preferences",
            meta: {
              needsAuth: true,
            },
            component: () => import("../views/general/preferences/detail"),
          },
        ],
      },
      {
        path: "raadsbesluit",
        meta: {
          helpTag: "public:raadsbesluit",
        },
        component: () =>
          import(
            "../views/public/raadsbesluit"
          ),
        children: [
          
          {
            path: "extended/:hexkey?",
            name: "Public.Raadsbesluit.ExtendedList",
            component: () =>
              import(
                "../components/general/lists/extendedList"
              ),
          },{
            path: ":hexkey?",
            name: "Public.Raadsbesluit",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },
      {
        path: "woorequest",
        meta: {
          helpTag: "public:woorequest",
        },
        component: () =>
          import(
            "../views/public/woorequest"
          ),
        children: [
          {
            path: ":hexkey?",
            name: "Public.WooRequest",
            component: () =>
              import(
                "../components/general/lists/simpleList"
              ),
          },
        ],
      },

      // dialogs in Homepage
      {
        path: "maillist/:action/:code/:key",
        redirect: (to) => {
          return {
            name: "Public.Homepage",
            params: {
              dialog: "public/maillist",
              action: to.params.action,
              code: to.params.code,
              key: to.params.key,
            },
          };
        },
      },
      {
        path: "registerconfirm/:action/:code/:lang",
        redirect: (to) => {
          return {
            name: "Public.Homepage",
            params: {
              dialog: "public/register",
              action: to.params.action,
              code: to.params.code,
              lang: to.params.lang,
            },
          };
        },
      },
      {
        path: "register",
        redirect: () => {
          return {
            name: "Public.Homepage",
            params: {
              dialog: "public/register",
            },
          };
        },
      },
      {
        path: "occasion/:action/:key",
        redirect: (to) => {
          return {
            name: "Public.Homepage",
            params: {
              dialog: "public/occasion",
              action: to.params.action,
              key: to.params.key,
            },
          };
        },
      },
      {
        path: "event/:key",
        redirect: (to) => {
          return {
            name: "Public.Homepage",
            params: {
              dialog: "public/event",
              action: "getDataDetails",
              key: to.params.key,
            },
          };
        },
      },
      {
        path: "signed/:status",
        redirect: (to) => {
          return {
            name: "Public.Homepage",
            params: {
              dialog: "public/signed",
              action: "getDataDetails",
              status: to.params.status,
            },
          };
        },
      },
    ],
  },
];
